import React from "react"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"

const BlockConfigurations = props => {
  return (
    <section className={`bg-black text-white`}>
      <div className={`container`}>
        <div
          className={`flex flex-col-reverse lg:flex-row-reverse justify-between items-center`}
        >
          {/* INTERNAL DOORS START */}
          {props.props === "internal-doors" && (
            <div className="w-full lg:w-7/12 lg:order-none order-first flex flex-row flex-wrap lg:pl-12 pl-0 lg:pt-0 pt-12">
              <Fade>
                <div className="w-1/5">
                  <Fade delay={100}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/2dd8134b-bec2-49a3-9a56-82de8158baf4_Deuren-Internal-Door-Config-Hinge-Right.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/2dd8134b-bec2-49a3-9a56-82de8158baf4_Deuren-Internal-Door-Config-Hinge-Right.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/2dd8134b-bec2-49a3-9a56-82de8158baf4_Deuren-Internal-Door-Config-Hinge-Right.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Hinge Right"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={200}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/f66d20fd-2cc4-4fb5-b48f-c19177771c07_Deuren-Internal-Door-Config-Pocket.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/f66d20fd-2cc4-4fb5-b48f-c19177771c07_Deuren-Internal-Door-Config-Pocket.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/f66d20fd-2cc4-4fb5-b48f-c19177771c07_Deuren-Internal-Door-Config-Pocket.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Pocket"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={300}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/7785d06d-0ca0-4be5-9429-005a0c54ba67_Deuren-Internal-Door-Config-Angled-Head.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/7785d06d-0ca0-4be5-9429-005a0c54ba67_Deuren-Internal-Door-Config-Angled-Head.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/7785d06d-0ca0-4be5-9429-005a0c54ba67_Deuren-Internal-Door-Config-Angled-Head.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Angled Head"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={400}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/436189df-e36d-4e25-8f6f-bd92e6c4f619_Deuren-Internal-Door-Config-Double.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/436189df-e36d-4e25-8f6f-bd92e6c4f619_Deuren-Internal-Door-Config-Double.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/436189df-e36d-4e25-8f6f-bd92e6c4f619_Deuren-Internal-Door-Config-Double.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Double"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={500}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/f09f2a57-5693-46d9-b679-ec35e8156abb_Deuren-Internal-Door-Config-Glazed-Lateral.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/f09f2a57-5693-46d9-b679-ec35e8156abb_Deuren-Internal-Door-Config-Glazed-Lateral.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/f09f2a57-5693-46d9-b679-ec35e8156abb_Deuren-Internal-Door-Config-Glazed-Lateral.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Glazed Lateral"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={600}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/ace9f354-3233-4a76-a99d-9766bcd972fb_Deuren-Internal-Door-Config-Sliding.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/ace9f354-3233-4a76-a99d-9766bcd972fb_Deuren-Internal-Door-Config-Sliding.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/ace9f354-3233-4a76-a99d-9766bcd972fb_Deuren-Internal-Door-Config-Sliding.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Sliding"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={700}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/06aeda6a-123f-46fc-984e-3457685e9ada_Deuren-Internal-Door-Config-Glazed-Transom.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/06aeda6a-123f-46fc-984e-3457685e9ada_Deuren-Internal-Door-Config-Glazed-Transom.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/06aeda6a-123f-46fc-984e-3457685e9ada_Deuren-Internal-Door-Config-Glazed-Transom.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Glazed Transom"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={800}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/cb4390fe-4504-4b9f-bb37-791d62dc6646_Deuren-Internal-Door-Config-Bifold.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/cb4390fe-4504-4b9f-bb37-791d62dc6646_Deuren-Internal-Door-Config-Bifold.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/cb4390fe-4504-4b9f-bb37-791d62dc6646_Deuren-Internal-Door-Config-Bifold.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Config Bifold"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={900}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/e10f55f7-823d-4bc9-9ad7-5f04cc6453e5_Deuren-Internal-Door-Config-Hinge-Left.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/e10f55f7-823d-4bc9-9ad7-5f04cc6453e5_Deuren-Internal-Door-Config-Hinge-Left.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/e10f55f7-823d-4bc9-9ad7-5f04cc6453e5_Deuren-Internal-Door-Config-Hinge-Left.png?auto=compress,format&w=180 1920w"
                      alt=" Internal Door Config Hinge Left"
                    />
                  </Fade>
                </div>
                <div className="w-1/5">
                  <Fade delay={1000}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/3428488e-68b0-4826-9cd0-f934ec4306e5_Deuren-Internal-Door-Config-Pivot.png?auto=compress,format&w=180"
                      srcSet="https://images.prismic.io/deuren/3428488e-68b0-4826-9cd0-f934ec4306e5_Deuren-Internal-Door-Config-Pivot.png?auto=compress,format&w=80 500w, https://images.prismic.io/deuren/3428488e-68b0-4826-9cd0-f934ec4306e5_Deuren-Internal-Door-Config-Pivot.png?auto=compress,format&w=180 1920w"
                      alt="Internal Door Config Pivot"
                    />
                  </Fade>
                </div>
              </Fade>
            </div>
          )}
          {/* INTERNAL DOORS END */}
          {/* FRONT DOORS START */}
          {props.props === "front-doors" && (
            <div className="w-full lg:w-7/12 lg:order-none order-first flex flex-row flex-wrap lg:pl-12 pl-0 lg:pt-0 pt-12">
              <Fade>
                <div className="w-1/3">
                  <Fade delay={100}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/11d2d20c-0f00-4eeb-bf8a-bedc1ca537db_Deuren-Front-Door-Config-Single.png?auto=compress,format&w=300"
                      srcSet="https://images.prismic.io/deuren/11d2d20c-0f00-4eeb-bf8a-bedc1ca537db_Deuren-Front-Door-Config-Single.png?auto=compress,format&w=130 500w, https://images.prismic.io/deuren/11d2d20c-0f00-4eeb-bf8a-bedc1ca537db_Deuren-Front-Door-Config-Single.png?auto=compress,format&w=300 1920w"
                      alt="Front Door Config Single"
                    />
                  </Fade>
                </div>
                <div className="w-1/3">
                  <Fade delay={200}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/7c5173e6-a991-45dc-9354-faa84ff7ce8c_Deuren-Front-Door-Config-Single-1-Lat.png?auto=compress,format&w=300"
                      srcSet="https://images.prismic.io/deuren/7c5173e6-a991-45dc-9354-faa84ff7ce8c_Deuren-Front-Door-Config-Single-1-Lat.png?auto=compress,format&w=130 500w, https://images.prismic.io/deuren/7c5173e6-a991-45dc-9354-faa84ff7ce8c_Deuren-Front-Door-Config-Single-1-Lat.png?auto=compress,format&w=300 1920w"
                      alt="Front Door Config Single"
                    />
                  </Fade>
                </div>
                <div className="w-1/3">
                  <Fade delay={300}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/87e08e6d-babf-49ce-93f7-95b091dd49f7_Deuren-Front-Door-Config-Single-2-Lat-Transom.png?auto=compress,format&w=300"
                      srcSet="https://images.prismic.io/deuren/87e08e6d-babf-49ce-93f7-95b091dd49f7_Deuren-Front-Door-Config-Single-2-Lat-Transom.png?auto=compress,format&w=130 500w, https://images.prismic.io/deuren/87e08e6d-babf-49ce-93f7-95b091dd49f7_Deuren-Front-Door-Config-Single-2-Lat-Transom.png?auto=compress,format&w=300 1920w"
                      alt="Front Door Config 2 Lat Transom"
                    />
                  </Fade>
                </div>
                <div className="w-1/3">
                  <Fade delay={400}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/42d66731-363b-43c7-a8cb-f77ec8010fde_Deuren-Front-Door-Config-Single-2-Lat.png?auto=compress,format&w=300"
                      srcSet="https://images.prismic.io/deuren/42d66731-363b-43c7-a8cb-f77ec8010fde_Deuren-Front-Door-Config-Single-2-Lat.png?auto=compress,format&w=130 500w, https://images.prismic.io/deuren/42d66731-363b-43c7-a8cb-f77ec8010fde_Deuren-Front-Door-Config-Single-2-Lat.png?auto=compress,format&w=300 1920w"
                      alt="Front Door Config Single 2 Lat"
                    />
                  </Fade>
                </div>
                <div className="w-1/3">
                  <Fade delay={500}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/847d86b7-1086-4bfa-b285-a95644f8b4be_Deuren-Front-Door-Config-Double.png?auto=compress,format&w=300"
                      srcSet="https://images.prismic.io/deuren/847d86b7-1086-4bfa-b285-a95644f8b4be_Deuren-Front-Door-Config-Double.png?auto=compress,format&w=130 500w, https://images.prismic.io/deuren/847d86b7-1086-4bfa-b285-a95644f8b4be_Deuren-Front-Door-Config-Double.png?auto=compress,format&w=300 1920w"
                      alt="Front Door Config Double"
                    />
                  </Fade>
                </div>
                <div className="w-1/3">
                  <Fade delay={600}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/5430f5fb-186b-495a-95e9-d1370ec213c0_Deuren-Front-Door-Config-Double-Transom.png?auto=compress,format&w=300"
                      srcSet="https://images.prismic.io/deuren/5430f5fb-186b-495a-95e9-d1370ec213c0_Deuren-Front-Door-Config-Double-Transom.png?auto=compress,format&w=130 500w, https://images.prismic.io/deuren/5430f5fb-186b-495a-95e9-d1370ec213c0_Deuren-Front-Door-Config-Double-Transom.png?auto=compress,format&w=300 1920w"
                      alt="Front Door Config Double Transom"
                    />
                  </Fade>
                </div>
              </Fade>
            </div>
          )}
          {/* FRONT DOORS END */}
          {/* GARAGE DOORS START */}
          {props.props === "garage-doors" && (
            <div className="w-full lg:w-7/12 lg:order-none order-first flex flex-row flex-wrap lg:pl-12 pl-0 lg:pt-0 pt-12">
              <Fade>
                <div className="w-1/2">
                  <Fade delay={100}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/93d4fc7a-b2a0-44ee-970a-cbbfd032d2ed_Garage-Door-Config-Overhead-Sectional.png?auto=compress,format&w=400"
                      srcSet="https://images.prismic.io/deuren/93d4fc7a-b2a0-44ee-970a-cbbfd032d2ed_Garage-Door-Config-Overhead-Sectional.png?auto=compress,format&w=200 500w, https://images.prismic.io/deuren/93d4fc7a-b2a0-44ee-970a-cbbfd032d2ed_Garage-Door-Config-Overhead-Sectional.png?auto=compress,format&w=400 1920w"
                      alt="Garage Door Config Overhead Sectional"
                    />
                  </Fade>
                </div>
                <div className="w-1/2">
                  <Fade delay={200}>
                    <img
                      className="w-full"
                      src="https://images.prismic.io/deuren/dc745a92-07ca-4d18-a1b6-df95150612d7_Garage-Door-Config-Side-Sectional.png?auto=compress,format&w=400"
                      srcSet="https://images.prismic.io/deuren/dc745a92-07ca-4d18-a1b6-df95150612d7_Garage-Door-Config-Side-Sectional.png?auto=compress,format&w=200 500w, https://images.prismic.io/deuren/dc745a92-07ca-4d18-a1b6-df95150612d7_Garage-Door-Config-Side-Sectional.png?auto=compress,format&w=400 1920w"
                      alt="Garage Door Config Side Sectional"
                    />
                  </Fade>
                </div>
              </Fade>
            </div>
          )}
          {/* GARAGE DOORS END */}
          <Fade direction="up">
            <div className="lg:w-5/12 w-full">
              <h1 className="font-display text-gold rfs:text-5xl">
                Any style, any
                <br /> configuration
              </h1>
              <div className="w-12 mt-5 separator"></div>
              <div className="mt-5 text-sm">
                {props.props === "garage-doors" && (
                  <p>
                    The doors showcasing your home needn’t be a purely practical
                    fixture. Long gone are the days when a garage door simply
                    served to provide access to your garage. At Deuren, we
                    specialise in beautifully crafted doors in a range of
                    configurations to not only capitalise on the latest in
                    innovative technology but also add kerb appeal to your
                    project.
                  </p>
                )}
                {props.props !== "garage-doors" && (
                  <p>
                    The doors within your home needn’t be a purely practical
                    fixture. Long gone are the days when{" "}
                    {props.props === "internal-doors"
                      ? "an internal door"
                      : props.props === "front-doors"
                      ? "a front door"
                      : "a garage door"}{" "}
                    simply served to provide access between spaces. At Deuren,
                    we specialise in beautifully crafted doors in any
                    configuration to not only capitalise on the latest in
                    innovative technology but also add the wow factor to any
                    room.
                  </p>
                )}
              </div>
              <Link
                to={`/${props.props}/configurations/`}
                className="btn gold mt-10"
              >
                See all configurations
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  )
}

export default BlockConfigurations
